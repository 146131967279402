import initializeApp from 'service/initializeApp';
import registerComponents from './helpers/registerComponents';
import {setContent} from 'localizations';
import inputSearchLang from 'localizations/inputSearch';
import date from 'localizations/date';
import buttonsLang from 'localizations/buttons';

import m from 'mithril';
import {createStore, applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';
import {init, store as Provider} from "_/lib/midux";

initializeApp();

// Регистрация локализаций, которые необходимы во всей авторизованной части проета.
setContent(inputSearchLang, date, buttonsLang);

// Объявление глобальных компонентов\библиотек для поддержки кода,
// который не обрабатывается webpack'ом.
registerComponents();

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))
);

init(m);
Provider(store);
