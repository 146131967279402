import m from "mithril";
import Component from 'lib/Component';
import {translate} from 'localizations';
import DateInput from "components/plugins/DateInput";
import Button from "components/plugins/Button";

class ChoiceDate extends Component {
    oninit() {
        this._value = this.attrs.value;
    }

    view() {
        let title = this.attrs.title;

        return (
            <div>
                <div className="modal__header bg-blue color-white">
                    <div className="modal-close font-icon cancel"/>
                    <span>{title}</span>
                </div>
                <div className="modal__body">
                    <DateInput
                        value={this._value}
                        onChange={(value) => (this._value = value)}
                        data-popup-key="modal-choice-date"
                        modal=".modal-choice-date"
                    />
                </div>
                <div className="modal__footer bg-grey text-right">
                    <Button
                        className="turbo-button__size_md turbo-button__bg_blue turbo-button__text_white modal-close"
                        onclick={() => (this.save())}
                    >
                        {translate("buttons.save")}
                    </Button>
                </div>
            </div>
        );
    }

    save() {
        let save = this.attrs.save;

        if (typeof save === "function") {
            save(this._value);
        }
    }
}

export default ChoiceDate;
