import turbo from 'lib/turbo';
import EventEmitter from 'lib/EventEmitter';
import progressbar from 'lib/progressbar';
import {ScrollBar} from 'components/plugins/ScrollBar';
import HandlerQueue from "lib/listeners/handlerQueue";
import EventListener from "lib/listeners/eventListener";
import {queueWS} from "lib/ws/ws";
import Button from "components/plugins/Button";
import SearchInput from "components/common/SearchInput";
import SearchEngine from "lib/searchEngine";
import {setHighLight} from "lib/highLight";
import CollectionDebounce from "lib/collectionDebounce";
import DatePicker from "components/plugins/DatePicker";
import DateInput from "components/plugins/DateInput";
import ChoiceDate from "components/dialogs/choiceDate";
import NumberInput from "components/plugins/NumberInput"
import {isShow, Role} from "_/lib/roleModel";

export default function() {
    turbo.namespace('helpers').EventEmitter          = EventEmitter;
    turbo.namespace('plugins').TurboScrollBar        = ScrollBar;
    turbo.namespace('plugins').progressbar           = progressbar;
    turbo.namespace('ui').TurboButton                = Button;
    turbo.namespace('plugins').SearchInput           = SearchInput;
    turbo.namespace('plugins').SearchEngine          = SearchEngine;
    turbo.namespace('plugins').setHighLight          = setHighLight;
    turbo.namespace("component.event").HandlerQueue  = HandlerQueue;
    turbo.namespace("component.event").EventListener = EventListener;
    turbo.namespace("ws").queueWS                    = queueWS;
    turbo.namespace('plugins').CollectionDebounce    = CollectionDebounce;
    turbo.namespace('plugins').DatePicker            = DatePicker;
    turbo.namespace('plugins').DateInput             = DateInput;
    turbo.namespace('dialogs').ChoiceDate            = ChoiceDate;
    turbo.namespace('ui').TurboNumberField           = NumberInput;
    turbo.namespace("roleModel").isShow              = isShow;
    turbo.namespace("roleModel").Role                = Role;
}
